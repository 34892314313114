.swal2-container{
  z-index: 99999 !important;
}

.overflow-y-contain {
  overflow-y: auto;
  overflow-x: hidden;
  height:calc(100vh - 90px);
}

.overflow-y-contain::-webkit-scrollbar {
  width: 5px;
  cursor: pointer;
  background-color: transparent;
}

.overflow-y-contain::-webkit-scrollbar-track {
  background: transparent;
  border: 1px solid #aeaeae;
  ;
  /* margin: 20px; */
}

.overflow-y-contain::-webkit-scrollbar-thumb {
  background: rgb(175, 175, 175);
  /* border-radius: 5px; */
}

.overflow-y-contain::-webkit-scrollbar-thumb:hover {
  background: rgb(145, 145, 145);
}



.overflow-y-tbl {
  overflow-y: auto;
  overflow-x: hidden;
  max-height:calc(100vh - 240px);
  height: fit-content;
}

.overflow-y-tbl::-webkit-scrollbar {
  width: 5px;
  cursor: pointer;
  background-color: transparent;
}

.overflow-y-tbl::-webkit-scrollbar-track {
  background: transparent;
  /* margin: 20px; */
}

.overflow-y-tbl::-webkit-scrollbar-thumb {
  background: grey;
  /* border-radius: 5px; */
}

.overflow-y-tbl::-webkit-scrollbar-thumb:hover {
  background: rgb(92, 92, 92);
}
@font-face {
  font-family: Jost;
  src: url('../src/utils/font/Jost-Regular.ttf');
}

*{
  font-family: Jost;
}

.overflow-x-contain {
overflow-x: auto;
overflow-y: hidden;

}

.overflow-x-contain::-webkit-scrollbar {
  height: 5px;
  cursor: pointer;
  background-color: transparent;
}

.overflow-x-contain::-webkit-scrollbar-track {
  background: transparent;
  /* margin: 20px; */
}

.overflow-x-contain::-webkit-scrollbar-thumb {
  background: rgb(92, 92, 92);
  /* background: #003399; */
  /* border-radius: 5px; */
}

.overflow-y-contain::-webkit-scrollbar-thumb:hover {
  background: rgb(92, 92, 92);
  /* background: #003399; */
}

.product-transition{
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.product-transition:hover{
  transform: scale(0.9);
}

.overflow-y-container {
  overflow-y: auto;
  overflow-x: hidden;
  height:calc(100vh - 90px);
}

.overflow-y-container::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
  background-color: transparent;
}

.overflow-y-container::-webkit-scrollbar-track {
  background: transparent;
  border: 1px solid #aeaeae;
  ;
  /* margin: 20px; */
}

.overflow-y-container::-webkit-scrollbar-thumb {
  background: grey;
  /* border-radius: 5px; */
}

.overflow-y-container::-webkit-scrollbar-thumb:hover {
  background: rgb(92, 92, 92);
}

.overflow-y{
  overflow-y: auto;
  overflow-x: hidden;
}

.overflow-y::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
  background-color: transparent;
}

.overflow-y::-webkit-scrollbar-track {
  background: transparent;
  border: 1px solid #aeaeae;
  ;
  /* margin: 20px; */
}

.overflow-y::-webkit-scrollbar-thumb {
  background: grey;
  /* border-radius: 5px; */
}

.overflow-y::-webkit-scrollbar-thumb:hover {
  background: rgb(92, 92, 92);
}

::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 1px solid #aeaeae;
  ;
  /* margin: 20px; */
}

::-webkit-scrollbar-thumb {
  background: grey;
  /* border-radius: 5px; */
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(92, 92, 92);
}


.overflow-x-contain-tabscroll {
  overflow-x: auto;
  overflow-y: hidden;
  
  }
  
  .overflow-x-contain-tabscroll::-webkit-scrollbar {
    height: 5px;
    cursor: pointer;
    background-color: transparent;
    display: none;
  }
  
  .overflow-x-contain-tabscroll::-webkit-scrollbar-track {
    background: transparent;
    /* margin: 20px; */
  }
  
  .overflow-x-contain-tabscroll::-webkit-scrollbar-thumb {
    background: grey;
    /* border-radius: 5px; */
  }

  .hide-scrollbar {
    overflow-x: auto;
    overflow-y: hidden;
    }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .overflow-x-contain-mobilenavsroll {
    overflow-x: auto;
    overflow-y: hidden;
    }
    
    .overflow-x-contain-mobilenavsroll::-webkit-scrollbar {
      height: 2px;
      cursor: pointer;
      background-color: transparent;
    }
    
    .overflow-x-contain-mobilenavsroll::-webkit-scrollbar-track {
      background: transparent;
      /* margin: 20px; */
    }
    
    .overflow-x-contain-mobilenavsroll::-webkit-scrollbar-thumb {
      background: rgb(140, 140, 140);
      /* border-radius: 5px; */
    }

    .overflow-x-stats-mobilenavsroll {
      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
      scrollbar-width: none; /* Hide scrollbar on Firefox */
      
      }
      
      .overflow-x-stats-mobilenavsroll::-webkit-scrollbar {
        display: none; 
        height: 2px;
        cursor: pointer;
        background-color: transparent;
      }
      
      .overflow-x-stats-mobilenavsroll::-webkit-scrollbar-track {
        background: transparent;
        /* margin: 20px; */
      }
      
      .overflow-x-stats-mobilenavsroll::-webkit-scrollbar-thumb {
        background: rgb(140, 140, 140);
        /* border-radius: 5px; */
      }
    
    .ql-editor {
      height:140px !important;
    }

    .tig-creator-iframe-area .tig-modal .tig-modal-content {
      width: calc(var(--vh, 1vh) * var(--tig-modal-height, 80) / 720 * 1280 + 128px) !important;
    }

    @media screen and (max-width: 767px) {
      .tig-creator-iframe-area .tig-modal .tig-modal-content {
          width: calc(var(--vh, 1vh) * var(--tig-modal-height, 80) / 16 * 9) !important;
      }
    }


    .bouncing-loader {
      margin-top: 3px;
      display: flex;
      justify-content: center;
    }
    
    .bouncing-loader > div {
      width: 6px;
      height: 6px;
      margin: 2px 4px;
      border-radius: 50%;
      background-color: #a3a1a1;
      opacity: 1;
      animation: bouncing-loader 0.6s infinite alternate;
    }

    @keyframes bouncing-loader {
      to {
        opacity: 0.1;
        transform: translateY(-8px);
      }
    }
    
    .bouncing-loader > div:nth-child(2) {
      animation-delay: 0.2s;
    }
    
    .bouncing-loader > div:nth-child(3) {
      animation-delay: 0.4s;
    }

    .grid-container {
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.3s ease;
    }
    
    .grid-container.open {
      max-height: 500px; /* adjust as needed */
    }
    